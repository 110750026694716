import React from "react";
import useJitsi from "./Jitsi.js";

export default function Conference(props) {
  // Si cambias este aprametro recuerda cambiarlo en el head (index.html en public)
  const apiDomain = "te.convoco.online";
  const options = {
    roomName: props.sala,
    width: "100%",
    height: "90vh",
    parentNode: "videoconferencia",
    configOverwrite: {
      startWithAudioMuted: true,
    },
    interfaceConfigOverwrite: {
      toolbarButtons: [
        "microphone",
        "camera",
        "closedcaptions",
        "desktop",
        "fullscreen",
        "fodeviceselection",
        "hangup",
        "profile",
        "chat",
        "settings",
        "raisehand",
        "videoquality",
        "filmstrip",
        "shortcuts",
        "tileview",
      ],
    },
  };

  const videoConference = useJitsi(options, apiDomain);

  return (
    <>
      <div id="videoconferencia">
        <noscript>
          Es necesario activar el javascript para el correcto funcionamiento de
          la página
        </noscript>
      </div>
    </>
  );
}
